import penguinEmperorAbi from 'config/abi/penguinEmperor.json'
import emperorPenguinDBAbi from 'config/abi/charityPenguin.json'
import { getPenguinEmperorAddress, getEmperorPenguinDBAddress } from 'utils/addressHelpers'
import { PenguinEmperor } from 'state/types'
import { getBalanceNumber } from 'utils/formatBalance'
import multicall from 'utils/multicall'
import { NON_ADDRESS } from 'config'

const penguinEmperorContractAddress = getPenguinEmperorAddress()
const emperorPenguinDBContractAddress = getEmperorPenguinDBAddress()

/*
 * Fetch general data
 */
export const fetchGeneralData = async () => {

  try {
    // get general data from emperor contract
    const generalDataCalls = [
      // maxBidIncrease
      {
        address: penguinEmperorContractAddress,
        name: 'maxBidIncrease',
      },
      // minBidIncrease
      {
        address: penguinEmperorContractAddress,
        name: 'minBidIncrease',
      },
      // openingBid
      {
        address: penguinEmperorContractAddress,
        name: 'openingBid',
      },
      // finalDate
      {
        address: penguinEmperorContractAddress,
        name: 'finalDate',
      },
      // poisonDuration
      {
        address: penguinEmperorContractAddress,
        name: 'poisonDuration',
      },
      // poisonCost
      {
        address: penguinEmperorContractAddress,
        name: 'poisonCost',
      },
    ]
    const [maxBidIncrease, minBidIncrease, openingBid, finalDate, poisonDuration, poisonCost] = await multicall(
      penguinEmperorAbi,
      generalDataCalls,
    )

    return {
      maxBidIncrease: getBalanceNumber(maxBidIncrease),
      minBidIncrease: getBalanceNumber(minBidIncrease),
      openingBid: getBalanceNumber(openingBid),
      finalDate: finalDate[0].toNumber(),
      poisonDuration: poisonDuration[0].toNumber(),
      poisonCost: getBalanceNumber(poisonCost)
    }
  } catch (error) {
    return {}
  }
}

/*
 * Fetch Penguin Emperor data
 * param: account: address
 */
export const fetchPenguinEmperorData = async (account) => {
  try {
    // from data from emperor contract
    const penguinEmperorContractCalls = [
      // penguinEmperorData with
      // timeAsEmperor   uint256 :  0
      // lastCrowningBlockTimestamp   uint256 :  0
      // timesCrownStolen   uint256 :  0
      // lastTimePoisoned   uint256 :  0
      // lastPoisonedBy   address :  0x0000000000000000000000000000000000000000
      // timesPoisoned   uint256 :  0
      // emperorsPoisoned 
      {
        address: penguinEmperorContractAddress,
        name: 'playerDB',
        params: [account],
      },
      // canBePoisoned
      {
        address: penguinEmperorContractAddress,
        name: 'canBePoisoned',
        params: [account],
      },
      // timePoisonedRemaining
      {
        address: penguinEmperorContractAddress,
        name: 'timePoisonedRemaining',
        params: [account],
      },
      // timeLeftForPoison
      {
        address: penguinEmperorContractAddress,
        name: 'timeLeftForPoison',
        params: [account],
      },

      // current emperor bid
      {
        address: penguinEmperorContractAddress,
        name: 'currentEmperorBid',
      },
    ]
    const [
      penguinEmperorData,
      canBePoisoned,
      // Need to add for penguinEmperor - V2
      // lastTimePoisoned,
      // lastPoisonedByAddress,
      timePoisonedRemaining,
      timeLeftForPoison,
    ] = await multicall(penguinEmperorAbi, penguinEmperorContractCalls)

    const { timeAsEmperor, lastCrowningBlockTimestamp } = penguinEmperorData

    // from data from emperorPenguinDB contract
    const emperorPenguinDBContractCalls = [
      // emperorPenguinData
      {
        address: emperorPenguinDBContractAddress,
        name: 'penguDB',
        params: [account],
      },
      // // lastPoisonedByNickname
      // {
      //   address: emperorPenguinDBContractAddress,
      //   name: 'nickname',
      //   params: [lastPoisonedBy],
      // },
    ]
    const [emperorPenguinData] = await multicall(
      emperorPenguinDBAbi,
      emperorPenguinDBContractCalls,
    )

    // Need to add for penguinEmperor - V2


    const { nickname, color, isRegistered, style } = emperorPenguinData

    return {
      // penguinEmperor
      address: account,
      lastCrowningBlockTimestamp: lastCrowningBlockTimestamp.toNumber(),
      timeAsEmperor: timeAsEmperor.toNumber(),
      canBePoisoned: canBePoisoned[0],
      // Need to add for penguinEmperor - V2
      // lastTimePoisoned: lastTimePoisoned[0].toNumber(),
      // lastPoisonedBy: lastPoisonedByNickname[0],
      timePoisonedRemaining: timePoisonedRemaining[0].toNumber(),
      timeLeftForPoison: timeLeftForPoison[0].toNumber(),
      // db data
      nickname,
      color,
      isRegistered,
      style: style.toNumber(),
    }
  } catch (error) {
    return {}
  }
}

/*
 * fetch current penguin Emperor data
 */
export const fetchCurrentPenguinEmperorData = async () => {
  try {
    // from current emperor data from emperor contract
    const penguinEmperorContractCalls = [
      // current emperor address
      {
        address: penguinEmperorContractAddress,
        name: 'currentEmperor',
      },
      // current emperor bid
      {
        address: penguinEmperorContractAddress,
        name: 'currentEmperorBid',
      },
      // current emperor jackpot
      {
        address: penguinEmperorContractAddress,
        name: 'jackpot',
      },
    ]
    const [currentPenguinEmperorAddress, currentPenguinEmperorBid, currentPenguinEmperorJackpot] = await multicall(
      penguinEmperorAbi,
      penguinEmperorContractCalls,
    )
    const data = await fetchPenguinEmperorData(currentPenguinEmperorAddress[0])

    return {
      ...data,
      currentPenguinEmperorAddress,
      bidAmount: getBalanceNumber(currentPenguinEmperorBid),
      jackpot: getBalanceNumber(currentPenguinEmperorJackpot),
    }
  } catch (error) {
    return {}
  }
}

/*
 * Fetch top 5 penguin emperor data
 */
export const fetchTopPenguinEmperors = async () => {
  const penguinEmperorContractCalls = [
    // current emperor address
    {
      address: penguinEmperorContractAddress,
      name: 'topEmperors',
      params: [0],
    },
    {
      address: penguinEmperorContractAddress,
      name: 'topEmperors',
      params: [1],
    },
    {
      address: penguinEmperorContractAddress,
      name: 'topEmperors',
      params: [2],
    },
    {
      address: penguinEmperorContractAddress,
      name: 'topEmperors',
      params: [3],
    },
    {
      address: penguinEmperorContractAddress,
      name: 'topEmperors',
      params: [4],
    },
  ]
  
  try {
    const topPenguinEmperorAddresses = await multicall(penguinEmperorAbi, penguinEmperorContractCalls)
    let promises = []
    for (let i = 0; i < 5; i++) {
      const topPenguinEmperorAddress = topPenguinEmperorAddresses[i][0]
      if (topPenguinEmperorAddress === NON_ADDRESS) {
        break
      }
      promises = [...promises, fetchPenguinEmperorData(topPenguinEmperorAddress)]
    }

    const topPenguinEmperors: PenguinEmperor[] = await Promise.all(promises)

    // eslint-disable-next-line consistent-return
    return topPenguinEmperors
  } catch (error) {
    // eslint-disable-next-line consistent-return
    return []
  }
}
