/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchGlobalData } from './fetchNewIgloo'
import { fetchUserData, fetchUserPuffiesData } from './fetchNewIglooUser'
import { PuffyIglooState } from '../types'

const initialState: PuffyIglooState = {
  totalStakedPuffies: 0,
  totalRewardAmount: 0,
  tokensPerSecond: 0,
  totalShares: 0,
  userStakedPuffiesAll: [],
  userTotalStakedPuffies: 0,
  userInfo: {
    amount: 0,
    rewardDebt: 0
  },
  pendingReward: 0,
  userStakedPuffies: [],
  tokensBestFriends: [],
  tokensNemesis: [],
  userBestFriendsData: [],
  userNemesisData: [],
  latestStakerToken: null,
  luckyPenguinToken: null,
  userPuffies: []
}

export const NewIglooSlice = createSlice({
  name: 'PuffyIgloo',
  initialState,
  reducers: {
    setIglooGlobalData: (state, action) => {
      state.totalStakedPuffies = action.payload.totalStakedPuffies
      state.totalRewardAmount = action.payload.totalRewardAmount
      state.tokensPerSecond = action.payload.tokensPerSecond
      state.totalShares = action.payload.totalShares
      state.latestStakerToken = action.payload.latestStakerToken
      state.luckyPenguinToken = action.payload.luckyPenguinToken 
    },
    setIglooUserData: (state, action) => {
      state.userStakedPuffiesAll = action.payload.userStakedPuffiesAll
      state.userTotalStakedPuffies = action.payload.userTotalStakedPuffies
      state.userInfo = { ...action.payload.userInfo }
      state.pendingReward = action.payload.pendingReward
      state.userStakedPuffies = [...action.payload.userStakedPuffies]
      state.tokensBestFriends = action.payload.tokensBestFriends
      state.tokensNemesis = action.payload.tokensNemesis
      state.userBestFriendsData = action.payload.userBestFriendsData
      state.userNemesisData = action.payload.userNemesisData
    },
    setUserPuffies: (state, action) => {
      state.userPuffies = [...action.payload.userPuffies];
    }
  },
})

// Actions
export const {
  setIglooGlobalData,
  setIglooUserData,
  setUserPuffies
} = NewIglooSlice.actions

export const fetchPuffyIglooGlobalDataAsync = () => async (dispatch) => {
  const { 
    totalStakedPuffies, 
    totalRewardAmount, 
    tokensPerSecond, 
    totalShares, 
    latestStakerToken,
    luckyPenguinToken 
  } = await fetchGlobalData()
  
  // const tierHurdles = await fetchTierHurdles()
  dispatch(
    setIglooGlobalData({
      totalStakedPuffies, 
      totalRewardAmount, 
      tokensPerSecond, 
      totalShares,
      latestStakerToken,
      luckyPenguinToken 
    }),
  )
}

export const fetchPuffyIglooUserDataAsync = account => async (dispatch) => {
  const userData = await fetchUserData(account);
  if (userData) {
    const { 
      userStakedPuffiesAll, 
      userTotalStakedPuffies, 
      userInfo, 
      pendingReward, 
      userStakedPuffies, 
      tokensBestFriends, 
      tokensNemesis, 
      userBestFriendsData, 
      userNemesisData
    } = userData;
    dispatch(
      setIglooUserData({
        userStakedPuffiesAll, 
        userTotalStakedPuffies, 
        userInfo, 
        pendingReward, 
        userStakedPuffies, 
        tokensBestFriends, 
        tokensNemesis, 
        userBestFriendsData, 
        userNemesisData
      })
    )
  }
}

export const fetchUserPuffies = account => async (dispatch) => {
  const userPuffies = await fetchUserPuffiesData(account);

  if (userPuffies) {
    dispatch(
      setUserPuffies({
        userPuffies
      })
    )
  }
}

export default NewIglooSlice.reducer
