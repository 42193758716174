import iglooABI from 'config/abi/puffyOldIgloo.json'
import multicall from 'utils/multicall'
import { getPuffyOldIglooAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'

export const fetchGlobalData = async () => {
  const [totalStakedPuffies, totalRewardAmount, tokensPerSecond, totalShares] = await multicall(
    iglooABI,
    [
      {
        address: getPuffyOldIglooAddress(),
        name: 'totalStakedPuffies',
      },
      {
        address: getPuffyOldIglooAddress(),
        name: 'totalRewardAmount',
      },
      {
        address: getPuffyOldIglooAddress(),
        name: 'tokensPerSecond',
      },
      {
        address: getPuffyOldIglooAddress(),
        name: 'totalShares'
      }
    ],
  )

  return {
    totalStakedPuffies: totalStakedPuffies[0].toNumber(),
    totalRewardAmount: getBalanceNumber(totalRewardAmount),
    tokensPerSecond: getBalanceNumber(tokensPerSecond),
    totalShares: totalShares[0].toNumber()
  }
}

export default fetchGlobalData;
