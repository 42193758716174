import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { connectorLocalStorageKey, ConnectorNames } from 'penguinfinance-uikit2'
import { connectorsByName } from 'utils/web3React'

const usePersistConnect = () => {
  const { activate } = useWeb3React()

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames
    const connector = connectorsByName[connectorId]

    if (connector) {
      activate(connector);
    }
  }, [activate]);

  // useEffect(() => {
  //   const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames

  //   if (connectorId) {
  //     login(connectorId)
  //   }
  // }, [login])
}

export default usePersistConnect
