/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchGeneralData, fetchPenguinEmperorData, fetchCurrentPenguinEmperorData, fetchTopPenguinEmperors, fetchPenguinPotionList } from './fetchPenguinEmperorData'
import { PenguinEmperorV3State } from '../types'

const initialState: PenguinEmperorV3State = {
    myEmperor: {},
    currentEmperor: {},
    topEmperors: [],
    maxBidIncrease: 0,
    minBidIncrease: 0,
    openingBid: 0,
    finalDate: 0,
    poisonCost: 0,
    startDate: 0,
    exchangeRateOfPlayingToken: 1,
    stealCrownList: []
}

export const PenguinEmperorV3Slice = createSlice({
  name: 'PenguinEmperorV3',
  initialState,
  reducers: {
    setInitialData: (state) => {
      state.currentEmperor = {}
    },
    setGeneralData: (state, action) => {
      const { finalDate, maxBidIncrease, minBidIncrease, openingBid, poisonCost, startDate, exchangeRateOfPlayingToken } = action.payload
      if (finalDate) {
        state.finalDate = finalDate
      }
      if (startDate) {
        state.startDate = startDate;
      }
      if (maxBidIncrease) {
        state.maxBidIncrease = maxBidIncrease
      }
      if (minBidIncrease) {
        state.minBidIncrease = minBidIncrease
      }
      if (openingBid) {
        state.openingBid = openingBid
      }
      if (poisonCost) {
        state.poisonCost = poisonCost
      }
      if (exchangeRateOfPlayingToken) {
        state.exchangeRateOfPlayingToken = exchangeRateOfPlayingToken
      }
    },

    setMyEmperor: (state, action) => {
      state.myEmperor = {
        ...state.myEmperor,
        ...action.payload,
      }
    },
    setCurrentEmperor: (state, action) => {
      state.currentEmperor = {
        ...state.currentEmperor,
        ...action.payload,
      }
    },
    setTopEmperors: (state, action) => {
      state.topEmperors = [...action.payload]
    },
    setStealCrownList: (state, action) => {
      state.stealCrownList = action.payload
    }
  },
})

// Actions
export const { setGeneralData, setInitialData, setMyEmperor, setCurrentEmperor, setTopEmperors, setStealCrownList } = PenguinEmperorV3Slice.actions


// Thunks
export const setInit = () => async (dispatch) => {
    dispatch(setInitialData())
}

export const fetchPenguinEmperorV3 = (account) => async (dispatch) => {

    // fetch my emperor
    if (account) {

        const myEmperor = await fetchPenguinEmperorData(account)
        dispatch(
            setMyEmperor({
                ...myEmperor,
            }),
        )
    }

    // fetch general Info
    const generalData = await fetchGeneralData()
    dispatch(setGeneralData({ ...generalData }))

    // fetch current emperor
    const currentEmperorData = await fetchCurrentPenguinEmperorData()
    dispatch(
        setCurrentEmperor({
            ...currentEmperorData,
        }),
    )

    // fetch top 5 emperors
    const topEmperors = await fetchTopPenguinEmperors()
    dispatch(setTopEmperors(topEmperors))
}

export const fetchPenguinStealAndPoisonList = () => async (dispatch) => {
  const stealCrownList = await fetchPenguinPotionList();

  if (stealCrownList && stealCrownList.length >= 1) {
    dispatch(setStealCrownList(stealCrownList));
  }
};

export default PenguinEmperorV3Slice.reducer
