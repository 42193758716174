/* eslint-disable no-await-in-loop */
import axios from 'axios';
import { isEmpty } from 'lodash';
import cryptoPuffiesABI from 'config/abi/cryptoPuffies.json'
import cryptoPuffiesGoldenTicketABI from 'config/abi/goldenTicket.json'
import multicall from 'utils/multicall'
import { sleep } from 'utils/time'
import { getCryptoPuffiesAddress, getCryptoGoldenTicketAddress, getPuffyOldIglooAddress, getCryptoPuffiesBestFriendsAddress, getPuffyIglooAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { fetchToken } from 'state/puffyIgloo/fetchNewIglooUser'

export const fetchTokenData = async (tokenUrl) => {
  let loop = true;
  let tokenData = null;
  while (loop) {
    tokenData = await axios.get(tokenUrl);
    if (isEmpty(tokenData)) {
      await sleep(1000)
    } else {
      loop = false
    }
  }

  const tokenId = tokenUrl.split('/')[tokenUrl.split('/').length - 1];

  const [attack, defense, strength, hp, bestFriend, nemesis] = await multicall(
    cryptoPuffiesABI,
    [
      {
        address: getCryptoPuffiesAddress(),
        name: 'attack',
        params: [tokenId],
      },
      {
        address: getCryptoPuffiesAddress(),
        name: 'defense',
        params: [tokenId],
      },
      {
        address: getCryptoPuffiesAddress(),
        name: 'strength',
        params: [tokenId]
      },
      {
        address: getCryptoPuffiesAddress(),
        name: 'health',
        params: [tokenId]
      },
      {
        address: getCryptoPuffiesBestFriendsAddress(),
        name: 'bestFriend',
        params: [tokenId]
      },
      {
        address: getCryptoPuffiesBestFriendsAddress(),
        name: 'nemesis',
        params: [tokenId]
      }
    ],
  );

  return {
    ...tokenData,
    data: {
      ...tokenData.data,
      attack: attack[0],
      defense: defense[0],
      strength: strength[0],
      hp: hp[0],
      bestFriend: bestFriend[0], 
      nemesis: nemesis[0],
      imageUrl: `https://penguinfinance.mypinata.cloud/ipfs/QmZxvA92oNfYhaQPanLboMAYvWDy94bakkemwfoUM1fwP5/${tokenId}.jpg`
    }
  };
}

export const fetchUserData = async (account) => {
  try {
    const [tokensOfOwner, tokensMinted, isApprovedForAll, isNewIglooApprovedForAll] = await multicall(
      cryptoPuffiesABI,
      [
        {
          address: getCryptoPuffiesAddress(),
          name: 'tokensOfOwner',
          params: [account],
        },
        {
          address: getCryptoPuffiesAddress(),
          name: 'tokensMinted',
          params: [account],
        },
        {
          address: getCryptoPuffiesAddress(),
          name: 'isApprovedForAll',
          params: [account, getPuffyOldIglooAddress()]
        },
        {
          address: getCryptoPuffiesAddress(),
          name: 'isApprovedForAll',
          params: [account, getPuffyIglooAddress()]
        }
      ],
    )

    const userTokenIds = tokensOfOwner[0].map(tokenId => tokenId.toNumber());
    const tokensUrl = await multicall(cryptoPuffiesABI, userTokenIds.map(tokenId => ({
      address: getCryptoPuffiesAddress(),
      name: 'tokenURI',
      params: [tokenId]
    })));

    const tokensAllowances = await multicall(cryptoPuffiesABI, userTokenIds.map(tokenId => ({
      address: getCryptoPuffiesAddress(),
      name: 'getApproved',
      params: [tokenId]
    })));
    
    const tokensBestFriends = await multicall(cryptoPuffiesABI, userTokenIds.map(tokenId => ({
      address: getCryptoPuffiesBestFriendsAddress(),
      name: 'bestFriend',
      params: [tokenId]
    })));

    const tokensNemesis = await multicall(cryptoPuffiesABI, userTokenIds.map(tokenId => ({
      address: getCryptoPuffiesBestFriendsAddress(),
      name: 'nemesis',
      params: [tokenId]
    })));

    const tokensData: any[] = await Promise.all(tokensUrl.map(tokenUrl => fetchTokenData(tokenUrl[0])));

    const bestFriendIds = tokensBestFriends.map(bestFriend => bestFriend[0]);
    const bestFriendsData = await Promise.all(bestFriendIds.map(bestFriendId => fetchToken(bestFriendId)));

    const nemesisIds = tokensNemesis.map(nemesis => nemesis[0]);
    const nemesisData = await Promise.all(nemesisIds.map(nemesisId => fetchToken(nemesisId)));

    const [goldenTickets] = await multicall(
      cryptoPuffiesGoldenTicketABI,
      [
        {
          address: getCryptoGoldenTicketAddress(),
          name: 'balanceOf',
          params: [account],
        }
      ],
    )

    return {
      tokensOfOwner: [...tokensOfOwner[0].map(tokenId => tokenId.toNumber())],
      tokensMinted: tokensMinted[0].toNumber(),
      tokensData: tokensData.map((tokenData, index) => ({
        ...tokenData.data,
        allowance: tokensAllowances[index][0]
      })),
      goldenTickets: getBalanceNumber(goldenTickets),
      isApprovedForAll: isApprovedForAll[0],
      isNewIglooApprovedForAll: isNewIglooApprovedForAll[0],
      tokensBestFriends: tokensBestFriends.map(bestFriend => bestFriend[0]),
      tokensNemesis: tokensNemesis.map(nemesis => nemesis[0]),
      bestFriendsData,
      nemesisData
    }
  } catch (error) {
    return null;
  }
}

export const fetchUserGoldenTickets = async account => {
  try {
    const [goldenTickets] = await multicall(
      cryptoPuffiesGoldenTicketABI,
      [
        {
          address: getCryptoGoldenTicketAddress(),
          name: 'balanceOf',
          params: [account],
        }
      ],
    )

    return getBalanceNumber(goldenTickets)
  } catch (error) {
    // console.log('ant : error => ', error);
    return null;
  }
}

export default fetchUserData;