/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import throttle from "lodash/throttle";
import { useLocation } from 'react-router-dom';

const ArenaGameContext = React.createContext({
  gameActive: false,
  hideGameHeader: false,
  hideMyPuffiesHeader: true,
  toggleGameActive: (gameActive: boolean) => null,
  toggleGameHeader: (hideHeader: boolean) => null,
})

const ArenaGameContextProvider = ({ children }) => {
  const [isGameActive, setIsGameActive] = useState(false);
  const [hideGameHeader, setHideGameHeader] = useState(false);
  const [hideMyPuffiesHeader, setHideMyPuffiesHeader] = useState(true);
  const location = useLocation();

  const toggleGameActive = gameActive => {
    setIsGameActive(gameActive);
  };

  const toggleGameHeader = hideHeader => {
    setHideGameHeader(hideHeader);
  };

  useEffect(() => {
    if (location.pathname === '/my-puffies') {
      setHideMyPuffiesHeader(true);
    }
  }, [location]);

  useEffect(() => {
    const handleWheel = (event: { deltaY: any; }) => {
      if (event.deltaY > 0 && !hideMyPuffiesHeader && location.pathname === '/my-puffies') {
        setHideMyPuffiesHeader(true);
      }
      if (event.deltaY < 0 && hideMyPuffiesHeader && location.pathname === '/my-puffies') {
        setHideMyPuffiesHeader(false);
      }
    };
    const throttledHandleWheel = throttle(handleWheel, 200);
    
    window.addEventListener("wheel", throttledHandleWheel);
    return () => {
      window.removeEventListener("wheel", throttledHandleWheel);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, hideMyPuffiesHeader]);

  return (
    <ArenaGameContext.Provider
      value={{
        gameActive: isGameActive,
        hideGameHeader,
        hideMyPuffiesHeader,
        toggleGameActive,
        toggleGameHeader
      }}
    >
      {children}
    </ArenaGameContext.Provider>
  )
}

export { ArenaGameContext, ArenaGameContextProvider }
