import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import App from './App'
import Providers from './Providers'
import history from './routerHistory'

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Providers>
        <App />
      </Providers>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
