import React from 'react'
import styled from 'styled-components'
import LevelCard from './LevelCard';
import TopRankings from './TopRankings';
import ProfileHeader from './ProfileHeader';

interface MyProfileModalProps {
  onDismiss?: () => void
}

const StyledModal = styled.div`
  z-index: ${({ theme }) => theme.zIndices.modal + 1};
  // overflow-y: auto;
  // overflow-x: hidden;
  position: relative;
  background: ${({ theme }) => theme.isDark ? '#272044' : 'white'};
  border: 3px solid #EE3E40;
  box-shadow: 7.62774px 14.4079px 12.7129px rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  max-width: 1040px;

  @media (min-width: 968px) {
    border-radius: 96px;
    border: 9px solid #EE3E40;
  }
`;

const ModalContainer = styled.div`
  max-height: 90vh;
  padding-right: 8px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #D9D9D9;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: '#D9D9D9';
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #EE3E40;
  },
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackScreen = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 39, 113, 0.4);
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  opacity: 0.6;
  z-index: 10;
  pointer-events: initial;
`;

const MyProfileModal: React.FC<MyProfileModalProps> = ({
  onDismiss
}) => {
  return (
    <Wrapper>
      <BackScreen onClick={onDismiss} />
      <ModalContainer>
        <StyledModal>
          <ProfileHeader onDismiss={onDismiss} />
          <LevelCard />
          <TopRankings />
        </StyledModal>
      </ModalContainer>
    </Wrapper>
  )
}

export default MyProfileModal;