import newIglooABI from 'config/abi/puffyIgloo.json'
import multicall from 'utils/multicall'
import { getPuffyIglooAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { fetchToken } from 'state/puffyIgloo/fetchNewIglooUser'

export const fetchGlobalData = async () => {
  const [totalStakedPuffies, totalRewardAmount, tokensPerSecond, totalShares, latestStaker, luckyPenguin] = await multicall(
    newIglooABI,
    [
      {
        address: getPuffyIglooAddress(),
        name: 'totalStakedPuffies',
      },
      {
        address: getPuffyIglooAddress(),
        name: 'totalRewardAmount',
      },
      {
        address: getPuffyIglooAddress(),
        name: 'tokensPerSecond',
      },
      {
        address: getPuffyIglooAddress(),
        name: 'totalShares'
      },
      {
        address: getPuffyIglooAddress(),
        name: 'latestStaker'
      },
      {
        address: getPuffyIglooAddress(),
        name: 'luckyPenguin'
      }
    ],
  )

  let latestStakerToken = null;
  let luckyPenguinToken = null;
  if (latestStaker[0] !== '0x0000000000000000000000000000000000000000') {
    latestStakerToken = await fetchToken(latestStaker[1].toNumber());
  }
  if (luckyPenguin[0] !== '0x0000000000000000000000000000000000000000') {
    luckyPenguinToken = await fetchToken(luckyPenguin[1].toNumber());
  }

  return {
    totalStakedPuffies: totalStakedPuffies[0].toNumber(),
    totalRewardAmount: getBalanceNumber(totalRewardAmount),
    tokensPerSecond: getBalanceNumber(tokensPerSecond),
    totalShares: totalShares[0].toNumber(),
    latestStakerToken,
    luckyPenguinToken
  }
}

export default fetchGlobalData;
