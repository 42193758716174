/* eslint-disable no-await-in-loop */
import axios from 'axios'
import penguinEmperorAbi from 'config/abi/v3Emperor/emperorV3.json'
import emperorPenguinDBAbi from 'config/abi/emperorPenguinDB.json'
import iPefiABI from 'config/abi/iPefi.json'
import randomDistributorAbi from 'config/abi/v3Emperor/randomDistributor.json'
import BigNumber from 'bignumber.js'
import abiDecoder from 'abi-decoder'
import { AbiItem } from 'web3-utils'
import { getPenguinEmperorV3Address, getEmperorPenguinDBAddress, getPefiAddress, getRandomDistributorAddress, getIPefiAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import multicall from 'utils/multicall'
import { NON_ADDRESS } from 'config'
import { getWeb3 } from 'utils/web3'

const penguinEmperorV3ContractAddress = getPenguinEmperorV3Address()
const emperorPenguinDBContractAddress = getEmperorPenguinDBAddress()
const randomDistributorContractAddress= getRandomDistributorAddress()

/*
 * Fetch general data
 */
export const fetchGeneralData = async () => {

  try {
    // get general data from emperor contract
    const generalDataCalls = [
      // maxBidIncrease
      {
        address: penguinEmperorV3ContractAddress,
        name: 'maxBidIncrease',
      },
      // minBidIncrease
      {
        address: penguinEmperorV3ContractAddress,
        name: 'minBidIncrease',
      },
      // openingBid
      {
        address: penguinEmperorV3ContractAddress,
        name: 'openingBid',
      },
      // finalDate
      {
        address: penguinEmperorV3ContractAddress,
        name: 'finalDate',
      },
      // poisonCost
      // {
      //   address: penguinEmperorV3ContractAddress,
      //   name: 'poisonCost',
      // },
      // startDate
      {
        address: penguinEmperorV3ContractAddress,
        name: 'startDate',
      },
      // exchangeRateOfPlayingToken
      {
        address: penguinEmperorV3ContractAddress,
        name: 'exchangeRateOfPlayingToken',
        params: [getPefiAddress()]
      }
    ]
    const [maxBidIncrease, minBidIncrease, openingBid, finalDate, startDate, exchangeRateOfPlayingToken] = await multicall(
      penguinEmperorAbi,
      generalDataCalls,
    )

    return {
      maxBidIncrease: getBalanceNumber(maxBidIncrease),
      minBidIncrease: getBalanceNumber(minBidIncrease),
      openingBid: getBalanceNumber(openingBid),
      finalDate: finalDate[0],
      // poisonCost: getBalanceNumber(poisonCost),
      startDate: startDate[0],
      exchangeRateOfPlayingToken: getBalanceNumber(exchangeRateOfPlayingToken)
    }
  } catch (error) {
    return {}
  }
}

const fetchPenguinData = async account => {
  const emperorPenguinDBContractCalls = [
    // emperorPenguinData
    {
      address: emperorPenguinDBContractAddress,
      name: 'profiles',
      params: [account],
    },
    {
      address: emperorPenguinDBContractAddress,
      name: 'builtInStyles',
      params: [account],
    }
  ];

  const [emperorPenguinData, builtInStyles] = await multicall(
    emperorPenguinDBAbi,
    emperorPenguinDBContractCalls,
  );

  const { nickname, activeAvatarType, avatarId } = emperorPenguinData
  const { color, style } = builtInStyles;

  return {
    nickname,
    color,
    style: style.toNumber(),
    activeAvatarType, 
    avatarId: avatarId.toNumber(),
  }
};

export const fetchPenguinPotionList = async () => {
  const web3 = getWeb3();
  try {
    const transactionsList = await axios.get(`https://api.snowtrace.io/api?module=account&action=txlist&address=${getPenguinEmperorV3Address()}&startblock=1&endblock=99999999&sort=asc&apikey=NRWGATQ871FH3ZAW6U7IEDFM15ZD96I6EJ`);
    const transactions = transactionsList?.data?.result;
    const toxinEncodedHex = web3.eth.abi.encodeFunctionSignature('castToxin()');
    const hailStormEncodedHex = web3.eth.abi.encodeFunctionSignature('castHailStorm()');
    const stealCrownEncodedHex = '0x6561a3e2';
    abiDecoder.addABI(penguinEmperorAbi)
    
    const stealCrownList = [];
    if (transactions) {
      let stealAndPoisonTransactions = transactions.filter(transaction => {
        if (transaction.isError === '0' &&
          (transaction.input.toLowerCase().includes(toxinEncodedHex.toLocaleLowerCase())
          || transaction.input.toLowerCase().includes(hailStormEncodedHex.toLocaleLowerCase())
          || transaction.input.toLowerCase().includes(stealCrownEncodedHex.toLocaleLowerCase()))) {
          return true;
        }
        return false;
      });

      stealAndPoisonTransactions.reverse();
      stealAndPoisonTransactions = stealAndPoisonTransactions.slice(0, 80);

      // eslint-disable-next-line no-restricted-syntax
      for (const transaction of stealAndPoisonTransactions) {
        // if (transaction.isError === '0' ) {
          if (transaction.input.toLowerCase().includes(toxinEncodedHex.toLocaleLowerCase())) {
            const penguin = await fetchPenguinData(transaction.from);
            const receipt = await web3.eth.getTransactionReceipt(transaction.hash);
            const decodedLogs = abiDecoder.decodeLogs(receipt.logs); 

            const hitByToxinLog = decodedLogs.find(log => log.name === 'HitByToxin');
            let kingPenguin;
            if (hitByToxinLog) {
              const effected = hitByToxinLog.events.find(event => event.name === 'effected');
              if (effected) {
                kingPenguin = await fetchPenguinData(effected.value);
              }
            }

            stealCrownList.push({
              type: 'toxin',
              from: transaction.from,
              timeStamp: transaction.timeStamp,
              penguin,
              kingPenguin
            })
          } else if (transaction.input.toLowerCase().includes(hailStormEncodedHex.toLocaleLowerCase())) {
            const penguin = await fetchPenguinData(transaction.from);
            const receipt = await web3.eth.getTransactionReceipt(transaction.hash);
            const decodedLogs = abiDecoder.decodeLogs(receipt.logs); 

            const hitByHailStormLog = decodedLogs.find(log => log.name === 'HitByHailStorm');
            let kingPenguin;
            if (hitByHailStormLog) {
              const effected = hitByHailStormLog.events.find(event => event.name === 'effected');
              if (effected) {
                kingPenguin = await fetchPenguinData(effected.value);
              }
            }
            stealCrownList.push({
              type: 'hailStorm',
              from: transaction.from,
              timeStamp: transaction.timeStamp,
              penguin,
              kingPenguin
            })
          } else if (transaction.input.toLowerCase().includes(stealCrownEncodedHex.toLocaleLowerCase())) {
            const penguin = await fetchPenguinData(transaction.from);
            const receipt = await web3.eth.getTransactionReceipt(transaction.hash);
            const decodedLogs = abiDecoder.decodeLogs(receipt.logs); 

            if (!decodedLogs.find(log => log.name === 'FailedBecauseToxin')) {
              const crownStolenLog = decodedLogs.find(log => log.name === 'CrownStolen');
              let kingPenguin;
              if (crownStolenLog) {
                const lastEmperor = crownStolenLog.events.find(event => event.name === 'lastEmperor');
                if (lastEmperor) {
                  kingPenguin = await fetchPenguinData(lastEmperor.value);
                }
              }
  
              stealCrownList.push({
                type: 'stealCrown',
                from: transaction.from,
                timeStamp: transaction.timeStamp,
                penguin,
                kingPenguin
              })
            }
          }
        // }
      }
    }
    
    return stealCrownList;
  } catch (error) {
    return null;
  }
};

/*
 * Fetch Penguin Emperor data
 * param: account: address
 */
export const fetchPenguinEmperorData = async (account) => {
  try {
    // from data from emperor contract
    const penguinEmperorContractCalls = [
      {
        address: penguinEmperorV3ContractAddress,
        name: 'playerDB',
        params: [account],
      },
      {
        address: penguinEmperorV3ContractAddress,
        name: 'statuses',
        params: [account],
      }
    ]
    const [
      penguinEmperorData,
      statuses
    ] = await multicall(penguinEmperorAbi, penguinEmperorContractCalls)

    const randomDistributorContractCalls = [
      {
        address: randomDistributorContractAddress,
        name: 'totalWinnings',
        params: [account],
      },
    ]
    const [totalWinnings] = await multicall(randomDistributorAbi, randomDistributorContractCalls)

    const web3 = getWeb3()
    const iPefibi = (iPefiABI as unknown) as AbiItem
    const iPefiContract = new web3.eth.Contract(iPefibi, getIPefiAddress())
    const allowanceBalance = (await iPefiContract.methods.allowance(account, getPenguinEmperorV3Address()).call()) / 1e18

    const { timeAsEmperor, lastCrowningBlockTimestamp } = penguinEmperorData

    // from data from emperorPenguinDB contract
    const emperorPenguinDBContractCalls = [
      // emperorPenguinData
      {
        address: emperorPenguinDBContractAddress,
        name: 'profiles',
        params: [account],
      },
      {
        address: emperorPenguinDBContractAddress,
        name: 'builtInStyles',
        params: [account],
      },
      {
        address: emperorPenguinDBContractAddress,
        name: 'isRegistered',
        params: [account],
      },
      {
        address: emperorPenguinDBContractAddress,
        name: 'canChangeName',
        params: [account],
      }
    ]
    const [emperorPenguinData, builtInStyles, isRegistered, canChangeName] = await multicall(
      emperorPenguinDBAbi,
      emperorPenguinDBContractCalls,
    )

    const { nickname, activeAvatarType, avatarId } = emperorPenguinData
    const { color, style } = builtInStyles;

    return {
      address: account,
      lastCrowningBlockTimestamp,
      timeAsEmperor,
      // db data
      nickname,
      color,
      isRegistered: isRegistered[0],
      style: style.toNumber(),
      activeAvatarType, 
      avatarId: avatarId.toNumber(),
      canChangeName: canChangeName[0],
      statuses: {
        toxinSpellsCast: statuses.toxinSpellsCast,
        toxinTimestamp: statuses.toxinTimestamp,
        hailStormsCast: statuses.hailStormsCast,
        frozenTimestamp: statuses.frozenTimestamp,
        bananaSpellsCast: statuses.bananaSpellsCast,
        perplexSpellsCast: statuses.perplexSpellsCast,
        vigorSpellsCast: statuses.vigorSpellsCast,
        vigorTimestamp: statuses.vigorTimestamp
      },
      allowanceBalance,
      totalWinnings: getBalanceNumber(totalWinnings[0])
    }
  } catch (error) {
    return {}
  }
}

/*
 * fetch current penguin Emperor data
 */
export const fetchCurrentPenguinEmperorData = async () => {
  try {
    // from current emperor data from emperor contract
    const penguinEmperorContractCalls = [
      // current emperor address
      {
        address: penguinEmperorV3ContractAddress,
        name: 'currentEmperorAndBid',
      },
      // current emperor jackpot
      {
        address: penguinEmperorV3ContractAddress,
        name: 'jackpot',
      },
    ]
    const [currentPenguinEmperorAndBid, currentPenguinEmperorJackpot] = await multicall(
      penguinEmperorAbi,
      penguinEmperorContractCalls,
    )
    const data = await fetchPenguinEmperorData(currentPenguinEmperorAndBid[0])
    
    return {
      ...data,
      currentPenguinEmperorAddress: currentPenguinEmperorAndBid[0],
      bidAmount: getBalanceNumber(new BigNumber(Number(currentPenguinEmperorAndBid[1]))),
      jackpot: getBalanceNumber(currentPenguinEmperorJackpot),
    }
  } catch (error) {
    return {}
  }
}

const fetchEmperor = async emperorNumber => {
  const penguinEmperorContractCalls = [
    {
      address: penguinEmperorV3ContractAddress,
      name: 'topEmperors',
      params: [emperorNumber],
    },
  ];

  try {
    const topPenguinEmperorAddresses = await multicall(penguinEmperorAbi, penguinEmperorContractCalls);
    const topPenguinEmperorAddress = topPenguinEmperorAddresses[0][0]
      if (topPenguinEmperorAddress !== NON_ADDRESS) {
        const emperorPenguinDBContractCalls = [
          // emperorPenguinData
          {
            address: emperorPenguinDBContractAddress,
            name: 'profiles',
            params: [topPenguinEmperorAddress],
          },
          {
            address: emperorPenguinDBContractAddress,
            name: 'builtInStyles',
            params: [topPenguinEmperorAddress],
          }
        ]
        const [emperorPenguinData, builtInStyles] = await multicall(
          emperorPenguinDBAbi,
          emperorPenguinDBContractCalls,
        )

        const penguinEmperorStatusContractCalls = [
          {
            address: penguinEmperorV3ContractAddress,
            name: 'playerDB',
            params: [topPenguinEmperorAddress],
          },
          {
            address: penguinEmperorV3ContractAddress,
            name: 'statuses',
            params: [topPenguinEmperorAddress],
          },
        ]
        const [
          penguinEmperorData,
          statuses
        ] = await multicall(penguinEmperorAbi, penguinEmperorStatusContractCalls);

        const { timeAsEmperor } = penguinEmperorData
        const { nickname, activeAvatarType, avatarId } = emperorPenguinData
        const { color, style } = builtInStyles;

        return {
          address: topPenguinEmperorAddress,
          timeAsEmperor,
          nickname,
          color,
          style: style.toNumber(),
          activeAvatarType, 
          avatarId: avatarId.toNumber(),
          statuses: {
            toxinSpellsCast: statuses.toxinSpellsCast,
            toxinTimestamp: statuses.toxinTimestamp,
            hailStormsCast: statuses.hailStormsCast,
            frozenTimestamp: statuses.frozenTimestamp,
            bananaSpellsCast: statuses.bananaSpellsCast,
            perplexSpellsCast: statuses.perplexSpellsCast,
            vigorSpellsCast: statuses.vigorSpellsCast,
            vigorTimestamp: statuses.vigorTimestamp
          }
        }
        // const emperor = await fetchPenguinEmperorData(topPenguinEmperorAddress);
        // return emperor;
      }
      return null;
  } catch (error) {
    return null;
  }
};
/*
 * Fetch top 5 penguin emperor data
 */
export const fetchTopPenguinEmperors = async () => {
  try {
    const topPenguinEmperors = await Promise.all(Array(5).fill(0).map(async (data, index) => {
      const emperor = fetchEmperor(index);
      return emperor;
    }));

    return topPenguinEmperors.filter(emperor => !!emperor);
  } catch (error) {
    return [];
  }
}