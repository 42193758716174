import React from 'react';
import styled from 'styled-components';

const BackButton = ({ onBack }) => {
  return (
    <BackButtonContainer onClick={onBack}>
      <svg viewBox="0 0 448 512">
        <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
      </svg>
    </BackButtonContainer>
  );
};

const BackButtonContainer = styled.div`
  background: #392771;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  left: 16px;
  top: 72px;
  z-index: 5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);

  svg {
    width: 24px;
    height: 16px;
    fill: white;
  }

  @media (min-width: 968px) {
    left: 40px;
    top: 132px;
  }
`;

export default BackButton;
