import React, { useContext } from 'react'
import { Menu as UikitMenu, useModal } from 'penguinfinance-uikit2'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { use100vh } from 'react-div-100vh'
import { useLocation } from 'react-router-dom'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import { ArenaGameContext } from 'contexts/ArenaGameContext'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import useTokenBalance from 'hooks/useTokenBalance'
import { usePricePefiUsdt, useV2Pools, useUserGoldenTickets, usePenguinEmperorV3 } from 'state/hooks'
import { getPefiAddress, getIPefiAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import WalletConnectGuideModal from 'components/Modal/WalletConnectGuideModal'
import SettingModal from 'components/Modal/SettingModal'
import MyProfileModal from 'views/MyProfile/MyProfileModal'
import useWindowSize from 'hooks/useWindowSize'
import { config, socials, buyPefiLinks, laptopConfig } from './config'

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const { gameActive, hideGameHeader, hideMyPuffiesHeader } = useContext(ArenaGameContext);
  const { isDark, toggleTheme } = useTheme()
  const pefiPriceUsd = usePricePefiUsdt()
  const v2Pools = useV2Pools(account)
  const pefiBalance = useTokenBalance(getPefiAddress())
  const iPefiBalance = useTokenBalance(getIPefiAddress())
  const avaxBalance = useTokenBalance()
  const [onToggleSettingModal] = useModal(<SettingModal />)
  const goldenTickets = useUserGoldenTickets(account);
  const { myEmperor } = usePenguinEmperorV3()
  const myNickname = myEmperor.nickname
  const location = useLocation();
  const windowSize = useWindowSize();
  const height = use100vh();
  const isArenaPageMobile = gameActive && windowSize.width <= 968 && windowSize.width < windowSize.height && location.pathname === '/arena';
  const isHideHeader = ((location.pathname === '/arena' && hideGameHeader && windowSize.width >= 968) || 
    (location.pathname === '/my-puffies' && hideMyPuffiesHeader && windowSize.width >= 1400));
  const v2Nest = v2Pools.length > 0 ? v2Pools[0] : null
  const iPefiToPefiRatio = v2Nest.currentExchangeRate || 1
  const links = windowSize.width >= 968 && windowSize.width <= 1640 ? [...laptopConfig] : [...config];
  const clubIndex = links.findIndex((link) => link.label === 'The Club')

  links[clubIndex] = {
    ...links[clubIndex],
    badgeLightColor: '#3B88E7',
    badgeDarkColor: '#ffffff',
  }

  const [onToggleMyProfileModal] = useModal(
    <MyProfileModal />,
  )

  const handleMenuClick = menu => {
    if (menu === 'My Profile') {
      onToggleMyProfileModal();
    }
  };

  const handleViewProfileModal = () => {
    onToggleMyProfileModal();
  };

  return (
    <Container fullHeight={height} isArenaPageMobile={isArenaPageMobile}>
      <WalletConnectGuideModal />
      <UikitMenu
        account={account}
        nickname={myNickname || ''}
        login={login}
        logout={logout}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={selectedLanguage && selectedLanguage.code}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        penguinPriceUsd={pefiPriceUsd.toNumber() || 1}
        iPefiRatio={Number(iPefiToPefiRatio)}
        links={links}
        socials={socials}
        buyPefiLinks={buyPefiLinks}
        onSettingClick={onToggleSettingModal}
        pefiBalance={getBalanceNumber(pefiBalance).toFixed(3)}
        iPefiBalance={getBalanceNumber(iPefiBalance).toFixed(3)}
        avaxBalance={getBalanceNumber(avaxBalance).toFixed(3)}
        goldenTickets={goldenTickets || 0}
        onMenuClick={handleMenuClick}
        viewProfile
        hideHeader={isHideHeader}
        onViewProfile={handleViewProfileModal}
        {...props}
      />
    </Container>
  )
}

const Container = styled.div<{ isArenaPageMobile: boolean, fullHeight: number }>`
  transform: ${({ isArenaPageMobile }) => isArenaPageMobile && 'rotate(-90deg)'};
  transform-origin: ${({ isArenaPageMobile }) => isArenaPageMobile && 'left top'};
  /* Set content width to viewport height */
  width: ${({ isArenaPageMobile, fullHeight }) => isArenaPageMobile && `${fullHeight}px`};
  /* Set content height to viewport width */
  height: ${({ isArenaPageMobile }) => isArenaPageMobile && '100vw'};
  // overflow-x: hidden;
  position: ${({ isArenaPageMobile }) => isArenaPageMobile && 'absolute'};
  top: ${({ isArenaPageMobile }) => isArenaPageMobile && '100%'};
  left: ${({ isArenaPageMobile }) => isArenaPageMobile && '0'};
`;

export default Menu
