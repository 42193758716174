/* eslint-disable no-await-in-loop */
import newIglooABI from 'config/abi/puffyIgloo.json'
import cryptoPuffiesABI from 'config/abi/cryptoPuffies.json'
import multicall from 'utils/multicall'
import { getPuffyIglooAddress, getCryptoPuffiesAddress, getCryptoPuffiesBestFriendsAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { fetchTokenData } from 'state/cryptopuffies/fetchCryptoPuffiesUser'

export const fetchToken = async tokenId => {
  try {
    const tokenUrl = await multicall(cryptoPuffiesABI, [{
      address: getCryptoPuffiesAddress(),
      name: 'tokenURI',
      params: [tokenId]
    }]);

    const token = await fetchTokenData(tokenUrl[0][0]);
    return token.data;
  } catch (error) {
    return null;
  }
};

export const fetchUserData = async (account) => {
  try {
    const [userStakedPuffiesAll, userTotalStakedPuffies, userInfo, pendingReward] = await multicall(
      newIglooABI,
      [
        {
          address: getPuffyIglooAddress(),
          name: 'userStakedPuffiesAll',
          params: [account],
        },
        {
          address: getPuffyIglooAddress(),
          name: 'userTotalStakedPuffies',
          params: [account],
        },
        {
          address: getPuffyIglooAddress(),
          name: 'userInfo',
          params: [account],
        },
        {
          address: getPuffyIglooAddress(),
          name: 'pendingReward',
          params: [account],
        }
      ],
    )

    const tokenIds = userStakedPuffiesAll[0].map(tokenId => tokenId.toNumber());
    const tokensUrl = await multicall(cryptoPuffiesABI, tokenIds.map(tokenId => ({
      address: getCryptoPuffiesAddress(),
      name: 'tokenURI',
      params: [tokenId]
    })));
    
    const tokensData: any [] = await Promise.all(tokensUrl.map(tokenUrl => fetchTokenData(tokenUrl[0])));

    const tokensBestFriends = await multicall(cryptoPuffiesABI, tokenIds.map(tokenId => ({
      address: getCryptoPuffiesBestFriendsAddress(),
      name: 'bestFriend',
      params: [tokenId]
    })));

    const tokensNemesis = await multicall(cryptoPuffiesABI, tokenIds.map(tokenId => ({
      address: getCryptoPuffiesBestFriendsAddress(),
      name: 'nemesis',
      params: [tokenId]
    })));

    const bestFriendIds = tokensBestFriends.map(bestFriend => bestFriend[0]);
    const userBestFriendsData = await Promise.all(bestFriendIds.map(bestFriendId => fetchToken(bestFriendId)));

    const nemesisIds = tokensNemesis.map(nemesis => nemesis[0]);
    const userNemesisData = await Promise.all(nemesisIds.map(nemesisId => fetchToken(nemesisId)));

    return {
      userTotalStakedPuffies: userTotalStakedPuffies[0].toNumber(),
      userInfo: {
        amount: userInfo[0].toNumber(),
        rewardDebt: getBalanceNumber(userInfo[1])
      },
      userStakedPuffiesAll: [...userStakedPuffiesAll[0].map(tokenId => tokenId.toNumber())],
      userStakedPuffies: tokensData.map(tokenData => tokenData.data),
      pendingReward: getBalanceNumber(pendingReward),
      tokensBestFriends: tokensBestFriends.map(bestFriend => bestFriend[0]),
      tokensNemesis: tokensNemesis.map(nemesis => nemesis[0]),
      userBestFriendsData,
      userNemesisData
    }
  } catch (error) {
    return null;
  }
}

export const fetchUserPuffiesData = async (account) => {
  try {
    const [userStakedPuffiesAll] = await multicall(
      newIglooABI,
      [
        {
          address: getPuffyIglooAddress(),
          name: 'userStakedPuffiesAll',
          params: [account],
        }
      ]
    );
  
    const [tokensOfOwner] = await multicall(
      cryptoPuffiesABI,
      [
        {
          address: getCryptoPuffiesAddress(),
          name: 'tokensOfOwner',
          params: [account],
        }
      ]
    );
  
    const userPuffies = [...userStakedPuffiesAll[0].map(puffyId => ({
      tokenId: puffyId.toNumber(),
      address: getCryptoPuffiesAddress()
    })), ...tokensOfOwner[0].map(tokenId => ({
      tokenId: tokenId.toNumber(),
      address: getCryptoPuffiesAddress()
    }))];
    return userPuffies;
  } catch (error) {
    return null;
  }
}

export default fetchUserData;