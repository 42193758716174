/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchGlobalData } from './fetchIgloo'
import { fetchUserData } from './fetchIglooUser'
import { PuffyOldIglooState } from '../types'

const initialState: PuffyOldIglooState = {
  totalStakedPuffies: 0,
  totalRewardAmount: 0,
  tokensPerSecond: 0,
  totalShares: 0,
  userStakedPuffiesAll: [],
  userTotalStakedPuffies: 0,
  userInfo: {
    amount: 0,
    rewardDebt: 0
  },
  pendingReward: 0,
  userStakedPuffies: [],
  tokensBestFriends: [],
  tokensNemesis: [],
  userBestFriendsData: [],
  userNemesisData: []
}

export const IglooSlice = createSlice({
  name: 'PuffyOldIgloo',
  initialState,
  reducers: {
    setIglooGlobalData: (state, action) => {
      state.totalStakedPuffies = action.payload.totalStakedPuffies
      state.totalRewardAmount = action.payload.totalRewardAmount
      state.tokensPerSecond = action.payload.tokensPerSecond
      state.totalShares = action.payload.totalShares
    },
    setIglooUserData: (state, action) => {
      state.userStakedPuffiesAll = action.payload.userStakedPuffiesAll
      state.userTotalStakedPuffies = action.payload.userTotalStakedPuffies
      state.userInfo = { ...action.payload.userInfo }
      state.pendingReward = action.payload.pendingReward
      state.userStakedPuffies = [...action.payload.userStakedPuffies]
      state.tokensBestFriends = action.payload.tokensBestFriends
      state.tokensNemesis = action.payload.tokensNemesis
      state.userBestFriendsData = action.payload.userBestFriendsData
      state.userNemesisData = action.payload.userNemesisData
    },
  },
})

// Actions
export const {
  setIglooGlobalData,
  setIglooUserData
} = IglooSlice.actions

export const fetchPuffyOldIglooGlobalDataAsync = () => async (dispatch) => {
  const { totalStakedPuffies, totalRewardAmount, tokensPerSecond, totalShares } = await fetchGlobalData()
  
  // const tierHurdles = await fetchTierHurdles()
  dispatch(
    setIglooGlobalData({
      totalStakedPuffies, totalRewardAmount, tokensPerSecond, totalShares
    }),
  )
}

export const fetchPuffyOldIglooUserDataAsync = account => async (dispatch) => {
  const userData = await fetchUserData(account);
  if (userData) {
    const { userStakedPuffiesAll, userTotalStakedPuffies, userInfo, pendingReward, userStakedPuffies, tokensBestFriends, tokensNemesis, userBestFriendsData, userNemesisData } = userData;
    dispatch(
      setIglooUserData({
        userStakedPuffiesAll, userTotalStakedPuffies, userInfo, pendingReward, userStakedPuffies, tokensBestFriends, tokensNemesis, userBestFriendsData, userNemesisData
      })
    )
  }
}

export default IglooSlice.reducer
