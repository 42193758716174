import React from 'react'
import styled from 'styled-components'
import { Text, Flex } from 'penguinfinance-uikit2'
import useWindowSize from 'hooks/useWindowSize';

const CardWrapper = styled(Flex)`
  background: #564BAB;
  box-shadow: 4px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  width: 100%;
  padding: 20px 16px 10px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 968px) {
    flex-direction: row;
    align-items: flex-start;
    border-radius: 28px;
    padding: 42px 30px 20px;
  }
`;

const CardBoardImg = styled.img`
  width: 200px;

  @media (min-width: 968px) {
    margin-top: -40px;
    margin-left: -80px;
    width: 400px;
  }
`;

const LevelContainer = styled(Flex)`
  background: #EE3E40;
  box-shadow: 2.85583px 1.90389px 1.90389px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: 968px) {
    width: 500px;
    border-radius: 14px 0px 0px 14px;
    box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 34px;
  }
  @media (min-width: 1000px) {
    width: 540px;
  }
  @media (min-width: 1200px) {
    width: 560px;
  }
`;

const LevelHeaderTitle = styled(Text)`
  font-size: 22px;
  @media (min-width: 968px) {
    font-size: 46px;
  }
`;

const LevelInfoItem = styled(Flex)`
  background: #FFFFFF;
  box-shadow: 2.02841px 1.62273px 1.62273px rgba(0, 0, 0, 0.25);
  border-radius: 43px;
  margin-bottom: 8px;
  height: 24px;

  @media (min-width: 968px) {
    box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.25);;
    width: 400px;
    height: 48px;
    margin-bottom: 16px;
  }
`;

const LevelInfoValue = styled(Text)`
  width: 60px;
  text-align: center;
  font-size: 14px;

  @media (min-width: 968px) {
    font-size: 26px;
    width: 70px;
  }
`;

const LevelInfoLabel = styled(Text)`
  text-align: center;
  font-size: 14px;
  width: 140px;

  @media (min-width: 968px) {
    width: 230px;
    font-size: 26px;
  }
`;

const Wrapper = styled(Flex)`
  padding: 16px;

  @media (min-width: 968px) {
    padding: 64px 64px 46px;
  }
`;

const LevelsWrapper = styled(Flex)`
  width: 100%;
  @media (min-width: 968px) {
    width: unset;
  }
`;

const LEVEL_INFO = {
  level: 1,
  battleScore: 123,
  timeAsEmperor: 12,
  kill: 12,
  death: 3,
  puffies: 5
};

const LevelCard = () => {
  const windowSize = useWindowSize();

  return (
    <Wrapper flexDirection='column' alignItems='center' width='100%' padding='64px 64px 46px'>
      {windowSize.width < 968 && 
        <CardBoardImg src={`${process.env.PUBLIC_URL}/images/profile/card_board.png`} alt='card-board' />
      }
      <CardWrapper justifyContent='space-between'>
        <LevelsWrapper flexDirection='column' alignItems='center'>
          <LevelContainer justifyContent='center'>
            <LevelHeaderTitle color='#fff' fontWeight='700'>{`Level: ${LEVEL_INFO.level}`}</LevelHeaderTitle>
          </LevelContainer>
          <LevelInfoItem justifyContent='space-around' alignItems='center'>
            <LevelInfoLabel color='#000' fontWeight='700' fontSize='26px'>Battle Score</LevelInfoLabel>
            <LevelInfoValue color='#564BAB' fontWeight='700' fontSize='26px'>{LEVEL_INFO.battleScore}</LevelInfoValue>
          </LevelInfoItem>
          <LevelInfoItem justifyContent='space-around' alignItems='center'>
            <LevelInfoLabel color='#000' fontWeight='700' fontSize='26px'>Time As Emperor</LevelInfoLabel>
            <LevelInfoValue color='#564BAB' fontWeight='700' fontSize='26px'>{`${LEVEL_INFO.timeAsEmperor}hrs`}</LevelInfoValue>
          </LevelInfoItem>
          <LevelInfoItem justifyContent='space-around' alignItems='center'>
            <LevelInfoLabel color='#000' fontWeight='700' fontSize='26px'>Kill/Death Ratio</LevelInfoLabel>
            <LevelInfoValue textAlign='center' color='#564BAB' fontWeight='700' fontSize='26px'>{`${LEVEL_INFO.kill}/${LEVEL_INFO.death}`}</LevelInfoValue>
          </LevelInfoItem>
          <LevelInfoItem justifyContent='space-around' alignItems='center'>
            <LevelInfoLabel color='#000' fontWeight='700' fontSize='26px'>Amount of Puffies</LevelInfoLabel>
            <LevelInfoValue color='#564BAB' fontWeight='700' fontSize='26px'>{LEVEL_INFO.puffies}</LevelInfoValue>
          </LevelInfoItem>
        </LevelsWrapper>
        {windowSize.width >= 968 && 
          <CardBoardImg src={`${process.env.PUBLIC_URL}/images/profile/card_board.png`} alt='card-board' />
        }
      </CardWrapper>
    </Wrapper>
  )
}

export default LevelCard;