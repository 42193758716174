import React from 'react'
import styled from 'styled-components'
import { Text, Flex } from 'penguinfinance-uikit2'
import useWindowSize from 'hooks/useWindowSize';

const Wrapper = styled(Flex)`
  height: 60px;
  background: #EE3E40;
  box-shadow: 2.01108px 1.34072px 1.34072px rgba(0, 0, 0, 0.25);
  border-radius: 0 5px 5px 0;
  margin-right: 20px;
  position: relative;

  @media (min-width: 968px) {
    height: 110px;
    margin-right: 64px;
    box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 15px 15px 0px;
    padding-left: 60px;
  }
  @media (min-width: 1200px) {
    height: 110px;
    margin-right: 64px;
  }
`;

const RankingsCard = styled.div`
  background: #D9D9D9;
  box-shadow: 1.37313px 2.40299px 3.08955px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 14px 14px;
  padding: 16px 8px 10px;
  width: 100%;

  @media (min-width: 968px) {
    border-radius: 0 0 42px 42px;
    padding: 32px 48px 20px;
    box-shadow: 4px 7px 9px rgba(0, 0, 0, 0.25);
  }
`;

const RankingList = styled.div`
  max-height: 406px;
  overflow: auto;
  width: 100%;
  padding-right: 8px;
  padding-top: 16px;

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #D9D9D9;
    margin-top: 16px;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: '#D9D9D9';
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #EE3E40;
  },
`;

// const RankingsCardWrapper = styled.div`
//   padding-right: 8px;
//   padding-bottom: 16px;
//   max-height: 500px;
//   overflow: auto;
//   width: 100%;

//   &::-webkit-scrollbar-track {
//     border-radius: 8px;
//     background-color: #D9D9D9;
//     margin-top: 10px;
//     margin-bottom: 10px;
//   }
//   &::-webkit-scrollbar {
//     width: 4px;
//     background-color: '#D9D9D9';
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 2px;
//     background-color: #EE3E40;
//   },
// `;

const TOP_RANKINGS = [
  { name: 'User1234', ranking: 1, type: 'Titan', puffies: 16 },
  { name: 'User1234', ranking: 2, type: 'Emperor', puffies: 16 },
  { name: 'User1234', ranking: 2, type: 'Emperor', puffies: 16 },
  { name: 'User1234', ranking: 2, type: 'Emperor', puffies: 16 },
  { name: 'User1234', ranking: 2, type: 'Emperor', puffies: 16 },
  { name: 'User1234', ranking: 3, type: 'Chief', puffies: 16 },
  { name: 'User1234', ranking: 3, type: 'Chief', puffies: 16 },
  { name: 'User1234', ranking: 3, type: 'Chief', puffies: 16 },
  { name: 'User1234', ranking: 3, type: 'Chief', puffies: 16 },
  { name: 'User1234', ranking: 3, type: 'Chief', puffies: 16 },
  { name: 'User1234', ranking: 3, type: 'Chief', puffies: 16 },
];

const TopRankingsImg = styled.img`
  width: 90px;
  height: 90px;

  @media (min-width: 968px) {
    width: 164px;
    height: 164px;
  }
`;

const UserItem = styled(Flex)<{ ranking: number }>`
  height: 30px;
  background: ${({ ranking }) => ranking === 2 ? '#FFC4C4' : '#C0BCDD'};
  background: ${({ ranking }) => ranking === 1 && '#FFCF51'};
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 52px;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 16px;

  @media (min-width: 968px) {
    padding: 0 16px;
    height: 41px;
  }
`;

const UserName = styled(Text)<{ ranking: number }>`
  color: ${({ ranking }) => ranking === 1 ? '#483692' : '#000'};
  min-width: 72px;
  max-width: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;

  @media (min-width: 968px) {
    font-size: 16px;
    min-width: 84px;
    max-width: 84px;
  }
`;

const Ranking = styled(Text)<{ ranking: number }>`
  color: ${({ ranking }) => ranking === 1 ? '#483692' : '#000'};
  width: 28px;
  font-size: 14px;

  @media (min-width: 968px) {
    font-size: 16px;
  }
`;

const UserType =styled(Text)<{ ranking: number }>`
  color: ${({ ranking }) => ranking === 2 ? '#564BAB' : '#fff'};
  color: ${({ ranking }) => ranking === 1 && '#EE3E40'};
  min-width: 84px;
  max-width: 84px;
  text-align: center;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 968px) {
    font-size: 22px;
    min-width: 84px;
    max-width: 84px;
  }
`;

const UserPuffies = styled(Text)<{ ranking: number }>`
  color: ${({ ranking }) => ranking === 1 ? '#483692' : '#000'};
  min-width: 40px;
  // max-width: 84px;
  font-size: 14px;

  @media (min-width: 968px) {
    font-size: 16px;
    min-width: 84px;
    max-width: 84px;
  }
`;

const RankingImgWrapper = styled.div<{ ranking: number }>`
  background: white;
  border: ${({ ranking }) => ranking === 2 ? '2px solid #EE3E40' : '2px solid #564BAB'};
  border: ${({ ranking }) => ranking === 1 && '2px solid #FFCF51'};
  height: ${({ ranking }) => ranking === 1 ? '38px' : '24px'};
  width: ${({ ranking }) => ranking === 1 ? '38px' : '24px'};
  border-radius: 6px;
  margin-bottom: ${({ ranking }) => ranking === 1 && '16px'};

  @media (min-width: 968px) {
    height: ${({ ranking }) => ranking === 1 ? '50px' : '41px'};
    width: ${({ ranking }) => ranking === 1 ? '50px' : '41px'};
  }
`;

const PuffyImg = styled.img`
  width: 100%;
  height: 100%;
`;

const RankingsWrapper = styled(Flex)`
  padding: 0 20px 20px;
  @media (min-width: 968px) {
    padding: 0 84px 48px 64px;
  }
`;

const RankingsHeaderTitle = styled(Text)`
  font-size: 24px;
  font-weight: 800;
  color: white;
  margin-right: 10%;

  @media (min-width: 968px) {
    margin-right: 120px;
    font-size: 48px;
  }
`;

const TopRankings = () => {
  const windowSize = useWindowSize();
  return (
    <div>
      <Wrapper alignItems='center' justifyContent='space-around'>
        <TopRankingsImg src={`${process.env.PUBLIC_URL}/images/profile/top_rankings.png`} alt='top-rankings' />
        <RankingsHeaderTitle color='#fff'>Top Rankings</RankingsHeaderTitle>
      </Wrapper>
      <RankingsWrapper>
        {/* <RankingsCardWrapper> */}
          <RankingsCard>
            <RankingList>
              {TOP_RANKINGS.map((user, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <UserItem justifyContent='space-around' alignItems='center' key={`${user.name}-${index}`} ranking={user.ranking}>
                    <UserName fontWeight='800' fontSize='16px' ranking={user.ranking}>{user.name}</UserName>
                    <Flex width='50px'>
                      <RankingImgWrapper ranking={user.ranking}>
                        <PuffyImg src={`${process.env.PUBLIC_URL}/images/profile/puffy.png`} alt='puffy' />
                      </RankingImgWrapper>
                    </Flex>
                    <Ranking ml='4px' fontWeight='800' fontSize='16px' ranking={user.ranking}>{`#${user.ranking}`}</Ranking>
                    <UserType fontWeight='800' fontSize='22px' ranking={user.ranking}>{user.type}</UserType>
                    <UserPuffies fontWeight='800' fontSize='16px' ranking={user.ranking}>{`${user.puffies} ${windowSize.width < 480 ? 'P...' : 'Puffies'}`}</UserPuffies>
                  </UserItem>
                )
              })}
            </RankingList>
          </RankingsCard>
        {/* </RankingsCardWrapper> */}
      </RankingsWrapper>
    </div>
  )
}

export default TopRankings;