import { MenuEntry } from 'penguinfinance-uikit2'

export const config: MenuEntry[] = [
  {
    label: 'Play',
    icon: 'CrownIcon',
    items: [
      // {
      //   label: "Emperor Royale",
      //   // icon: 'CrownIcon',
      //   href: '/emperor'
      // },
      // {
      //   label: "Penguin Arena",
      //   // icon: "BattleIcon",
      //   href: "/arena",
      // },
      // {
      //   label: "My Puffies",
      //   // icon: 'CrownIcon',
      //   href: '/my-puffies',
      // },
      {
        label: 'Puffy Mint',
        // icon: 'CrownIcon',
        href: '/puffy-mint',
      },
      {
        label: 'Puffy Igloo',
        // icon: 'CrownIcon',
        href: '/puffy-igloo',
      },
      // {
      //   label: 'Puffy Lab',
      //   // icon: 'CrownIcon',
      //   href: '/puffy-lab',
      // },
    ],
  },
  {
    label: 'Emperor Royale',
    icon: 'CrownIcon',
    href: '/emperor',
  },
  {
    label: 'Earn',
    icon: 'IglooIcon',
    items: [
      {
        label: 'Stake',
        href: '/stake',
      },
      {
        label: 'Farm',
        href: '/farms',
      },
    ],
  },
  // {
  //   label: 'Launchpad',
  //   icon: 'LaunchpadIcon',
  //   href: '/launchpad',
  // },
  {
    label: 'Penguin Arena',
    icon: 'BattleIcon',
    href: '/arena',
  },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   items: [
  //     {
  //       label: "Collectibles",
  //       href: '/collectibles'
  //     },
  //     {
  //       label: "CryptoPuffies",
  //       href: "https://www.cryptopuffies.io/",
  //     },
  //   ],
  // },
  {
    label: 'My Puffies',
    icon: 'NftIcon',
    href: '/my-puffies',
  },
  // {
  //   label: 'My Profile',
  //   icon: 'InfoIcon',
  // },
  {
    label: 'Other',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Feedback & Support',
        href: '/contact-us',
      },
      {
        label: 'Documentation',
        // icon: "BattleIcon",
        href: 'https://docs.penguinfinance.io/',
      },
      {
        label: 'The Club',
        // icon: "ClubIcon",
        href: '/club',
      },
    ],
  },
]

export const laptopConfig: MenuEntry[] = [
  {
    label: 'Play',
    icon: 'CrownIcon',
    items: [
      {
        label: 'Emperor Royale',
        // icon: 'CrownIcon',
        href: '/emperor',
      },
      {
        label: 'Penguin Arena',
        // icon: 'BattleIcon',
        href: '/arena',
      },
      // {
      //   label: 'My Profile',
      //   href: '/profile',
      // },
    ],
  },
  {
    label: 'Puffy',
    icon: 'NftIcon',
    items: [
      {
        label: 'My Puffies',
        // icon: 'NftIcon',
        href: '/my-puffies',
      },
      {
        label: 'Puffy Mint',
        // icon: 'CrownIcon',
        href: '/puffy-mint',
      },
      {
        label: 'Puffy Igloo',
        // icon: 'CrownIcon',
        href: '/puffy-igloo',
      },
      // {
      //   label: "Puffy Lab",
      //   // icon: 'CrownIcon',
      //   href: '/puffy-lab'
      // },
    ],
  },
  {
    label: 'Earn',
    icon: 'IglooIcon',
    items: [
      {
        label: 'Stake',
        href: '/stake',
      },
      {
        label: 'Farm',
        href: '/farms',
      },
    ],
  },
  // {
  //   label: 'My Profile',
  //   icon: 'InfoIcon',
  // },
  {
    label: 'Other',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Feedback & Support',
        href: '/contact-us',
      },
      {
        label: 'Documentation',
        href: 'https://docs.penguinfinance.io/',
      },
      {
        label: 'The Club',
        href: '/club',
      },
    ],
  },
]

export const socials = [
  //   {
  //     label: 'Discord',
  //     icon: 'DiscordIcon',
  //     href: 'https://discord.gg/R5Rv68GXXc',
  //   },
  //   {
  //     label: 'Telegram',
  //     icon: 'TelegramIcon',
  //     href: 'https://t.me/penguin_defi',
  //   },
  //   {
  //     label: 'Twitter',
  //     icon: 'TwitterIcon',
  //     href: 'https://twitter.com/penguin_defi',
  //   },
]

export const buyPefiLinks = [
  {
    label: 'Buy on Trader Joe',
    href: 'https://traderjoexyz.com/#/trade?outputCurrency=0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
  },
  {
    label: 'Buy on Pangolin',
    href: 'https://app.pangolin.exchange/#/swap?outputCurrency=0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
  },
  {
    label: 'Buy on Lydia',
    href: 'https://exchange.lydia.finance/#/swap?inputCurrency=0xe896cdeaac9615145c0ca09c8cd5c25bced6384c',
  },
]
