import React from 'react'
import styled from 'styled-components'
import { Text, Flex, CloseIcon } from 'penguinfinance-uikit2'
import useWindowSize from 'hooks/useWindowSize';

interface ProfileHeaderProps {
  onDismiss?: () => void
}

const BackButton = styled(Flex)`
  cursor: pointer;
  background: #EE3E40;
  width: 24px;
  height: 24px;
  border-radius: 32px;
  box-shadow: 1.83333px 2.44444px 2.44444px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 24px;
  top: 24px;

  & svg {
    width: 20px;
    height: 20px;
    path {
      fill: white;
    }
  }

  @media (min-width: 968px) {
    position: relative;
    right: 0;
    top: 0;
    margin: 32px 40px 32px 0;
    width: 32px;
    height: 32px;

    & svg {
      width: 28px;
      height: 28px;
      path {
        fill: white;
      }
    }
  }
`;

const NameImgWrapper = styled.div`
  background: white;
  border-radius: 7px;
  border: 4px solid #564BAB;
  position: absolute;
  left: -10px;
  top: -12px;
  z-index: 2;
  @media (min-width: 968px) {
    border-radius: 20px;
    border: 6px solid #564BAB;
  }
`;

const NameImg = styled.img`
  position: relative;
  z-index: 2;
  width: 66px;
  @media (min-width: 968px) {
    width: 116px;
  }
`;

const NameWrapper = styled.div`
  height: 60px;
  width: calc(100% - 40px);
  background: linear-gradient(0deg, #EE3E40, #EE3E40), #EE3E40;
  border-radius: 22px 0 0 22px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 60px;
  margin-left: 60px;
  margin-top: 24px;
  margin-bottom: 24px;
  @media (min-width: 968px) {
    width: calc(100% - 92px);
    filter: drop-shadow(4.40636px 6.60955px 3.30477px rgba(0, 0, 0, 0.26));
    min-height: 110px;
    margin-right: 8px; 
    padding-left: 100px;
    margin-left: 100px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const TitleContainer = styled.div<{ width?: string }>`
  position: relative;
  height: 36px;
  width: 180px;
  filter: drop-shadow(6px 8px 4px rgba(0, 0, 0, 0.25));
  @media (min-width: 968x) {
    width: ${({ width }) => width || '270px'};
    height: 72px;
  }
  @media (min-width: 1300px) {
    width: ${({ width }) => width || '300px'};
  }
`;

const NameTitleContainer = styled(TitleContainer)<{ width?: string }>`
  position: relative;
  height: 48px;
  width: 80%;
  filter: drop-shadow(6px 8px 4px rgba(0, 0, 0, 0.25));
  @media (min-width: 968px) {
    width: ${({ width }) => width || '360px'};
    height: 72px;
  }
  @media (min-width: 1200px) {
    width: ${({ width }) => width || '440px'};
  }
`;

const TitleWrapper = styled.div`
  background: #FB4F65;
  border: 3px solid #564BAB;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (min-width: 968px) {
    border: 6px solid #564BAB;
    border-radius: 8px;
  }
`;

const TitleWrapperBg = styled.div`
  border: 6px solid white;
  position: absolute;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  z-index: -1;
  right: -2px;
  bottom: -2px;
  @media (min-width: 968px) {
    right: -4px;
    bottom: -4px;
    border-radius: 8px;
  }
`;

const Title = styled(Text)`
  color: #FFFFFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 800;
  @media (min-width: 968px) {
    font-size: 30px;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background: #564BAB;
  border: 2px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 2.5px;
  @media (min-width: 968px) {
    width: 42px;
    height: 42px;
    padding: 6px;
    border: 3.30657px solid #FFFFFF;
  }
`;

const CameraIconWrapper = styled(IconWrapper)`
  position: absolute;
  z-index: 3;
  bottom: -12px;
  left: 50px;
  @media (min-width: 968px) {
    bottom: -24px;
    left: 86px;
  }
`;

const PenIconWrapper = styled(IconWrapper)`
  position: relative;
  z-index: 3;
`;

const PenIconContainer = styled.div`
  background: linear-gradient(0deg,#FB455B,#FB455B),#FB455B;
  padding: 6px;
  border-radius: 100%;
  margin-top: -42px;
  margin-left: -20px;
  @media (min-width: 968px) {
    padding: 8px;
    margin-top: -84px;
    margin-left: -32px;
  }
`;

const ConnectButton = styled.img`
  cursor: pointer;
  width: 150px;

  @media (min-width: 968px) {
    width: 280px;
    margin-left: 32px;
  }
`;

const MyProfileTitle = styled(Text)`
  color: #564BAB;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 8px;
  text-shadow: rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px, rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989992px 0.14112px 0px, rgb(255, 255, 255) -0.653644px -0.756802px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px, rgb(255, 255, 255) 0.96017px -0.279415px 0px;
`;

const MobileWrapper = styled(Flex)`
  background: #EE3E40;
  padding: 16px;
  position: relative;
  border: 3px solid white;
  margin-left: -3px;
  margin-right: -3px;
  margin-top: -3px;
  z-index: 102; 
  border-radius: 32px 32px 0 0;
  filter: drop-shadow(2.14161px 3.21241px 1.60621px rgba(0, 0, 0, 0.26));;
`;

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  onDismiss
}) => {
  const windowSize = useWindowSize();
  return (
    windowSize.width >= 968 ?
    <>
      <Flex justifyContent='flex-end'>
        <BackButton alignItems='center' justifyContent='center' onClick={onDismiss} mt='32px' mr='40px' mb='32px'>
          <CloseIcon />
        </BackButton>
      </Flex>
      <NameWrapper>
        <NameImgWrapper>
          <NameImg src={`${process.env.PUBLIC_URL}/images/profile/puffy.png`} alt='puffy' />
        </NameImgWrapper>
        <CameraIconWrapper>
          <img src={`${process.env.PUBLIC_URL}/images/profile/camera.svg`} alt='camera' />
        </CameraIconWrapper>
        <NameTitleContainer>
          <TitleWrapper>
            <Title>User Name...</Title>
          </TitleWrapper>
          <TitleWrapperBg />
        </NameTitleContainer>
        <PenIconContainer>
          <PenIconWrapper>
            <img src={`${process.env.PUBLIC_URL}/images/profile/pen.svg`} alt='pen' />
          </PenIconWrapper>
        </PenIconContainer>
        <ConnectButton src={`${process.env.PUBLIC_URL}/images/profile/connect_button.png`} alt='connect_button' />
      </NameWrapper>
    </>
    :
    <MobileWrapper flexDirection='column' alignItems='center'>
      <MyProfileTitle>My Profile</MyProfileTitle>
      <BackButton alignItems='center' justifyContent='center' onClick={onDismiss}>
        <CloseIcon />
      </BackButton>
      <NameWrapper>
        <NameImgWrapper>
          <NameImg src={`${process.env.PUBLIC_URL}/images/profile/puffy.png`} alt='puffy' />
        </NameImgWrapper>
        <CameraIconWrapper>
          <img src={`${process.env.PUBLIC_URL}/images/profile/camera.svg`} alt='camera' />
        </CameraIconWrapper>
        <NameTitleContainer>
          <TitleWrapper>
            <Title>User Name...</Title>
          </TitleWrapper>
          <TitleWrapperBg />
        </NameTitleContainer>
        <PenIconContainer>
          <PenIconWrapper>
            <img src={`${process.env.PUBLIC_URL}/images/profile/pen.svg`} alt='pen' />
          </PenIconWrapper>
        </PenIconContainer>
      </NameWrapper>
      <ConnectButton src={`${process.env.PUBLIC_URL}/images/profile/connect_button.png`} alt='connect_button' />
    </MobileWrapper>
  )
}

export default ProfileHeader;