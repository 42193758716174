/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchGeneralData, fetchPenguinEmperorData, fetchCurrentPenguinEmperorData, fetchTopPenguinEmperors } from './fetchPenguinEmperorData'
import { PenguinEmperorState } from '../types'

const initialState: PenguinEmperorState = {
    myEmperor: {},
    currentEmperor: {},
    topEmperors: [],
    maxBidIncrease: 0,
    minBidIncrease: 0,
    openingBid: 0,
    finalDate: 0,
    poisonDuration: 0,
    poisonCost: 0
}

export const PenguinEmperorSlice = createSlice({
    name: 'PenguinEmperor',
    initialState,
    reducers: {
        setInitialData: (state) => {
            state.currentEmperor = {}
        },
        setGeneralData: (state, action) => {
            const { finalDate, maxBidIncrease, minBidIncrease, openingBid, poisonDuration, poisonCost } = action.payload
            if (finalDate) {
                state.finalDate = finalDate
            }
            if (maxBidIncrease) {
                state.maxBidIncrease = maxBidIncrease
            }
            if (minBidIncrease) {
                state.minBidIncrease = minBidIncrease
            }
            if (openingBid) {
                state.openingBid = openingBid
            }
            if (poisonDuration) {
                state.poisonDuration = poisonDuration
            }
            if (poisonCost) {
                state.poisonCost = poisonCost
            }
        },

        setMyEmperor: (state, action) => {
            state.myEmperor = {
                ...state.myEmperor,
                ...action.payload,
            }
        },
        setCurrentEmperor: (state, action) => {
            state.currentEmperor = {
                ...state.currentEmperor,
                ...action.payload,
            }
        },
        setTopEmperors: (state, action) => {
            state.topEmperors = [...action.payload]
        },
    },
})

// Actions
export const { setGeneralData, setInitialData, setMyEmperor, setCurrentEmperor, setTopEmperors } = PenguinEmperorSlice.actions


// Thunks
export const setInit = () => async (dispatch) => {
    dispatch(setInitialData())
}

export const fetchPenguinEmperor = (account) => async (dispatch) => {

    // fetch my emperor
    if (account) {

        const myEmperor = await fetchPenguinEmperorData(account)
        dispatch(
            setMyEmperor({
                ...myEmperor,
            }),
        )
    }

    // fetch general Info
    const generalData = await fetchGeneralData()
    dispatch(setGeneralData({ ...generalData }))

    // fetch current emperor
    const currentEmperorData = await fetchCurrentPenguinEmperorData()
    dispatch(
        setCurrentEmperor({
            ...currentEmperorData,
        }),
    )

    // fetch top 5 emperors
    const topEmperors = await fetchTopPenguinEmperors()
    dispatch(setTopEmperors(topEmperors))
}

export default PenguinEmperorSlice.reducer
