export default {
  pefi: {
    43113: '',
    43114: '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
  },
  masterChef: {
    43113: '',
    43114: '0x8AC8ED5839ba269Be2619FfeB3507baB6275C257',
  },
  gondolaMasterChef: {
    43113: '',
    43114: '0x34C8712Cc527a8E6834787Bd9e3AD4F2537B0f50',
  },
  lydiaMasterChef: {
    43113: '',
    43114: '0xFb26525B14048B7BB1F3794F6129176195Db7766',
  },
  joeMasterChef: {
    43113: '',
    43114: '0xd6a4F121CA35509aF06A0Be99093d08462f53052',
  },
  joeMasterChefV3: {
    43113: '',
    43114: '0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00',
  },
  benqiMasterChef: {
    43113: '',
    43114: '0x784DA19e61cf348a8c54547531795ECfee2AfFd1',
  },
  // v2
  v2MasterChef: {
    43113: '',
    43114: '0x256040dc7b3CECF73a759634fc68aA60EA0D68CB',
  },
  wavax: {
    43113: '',
    43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  },
  lottery: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  lotteryNFT: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  mulltiCall: {
    43113: '',
    43114: '0xb2a7b34C36caEBCFEdF4918b984a86B3224098d1',
  },
  busd: {
    43113: '',
    43114: '0xaEb044650278731Ef3DC244692AB9F64C78FfaEA',
  },
  usdt: {
    43113: '',
    43114: '0xde3A24028580884448a5397872046a019649b084',
  },
  pancakeProfile: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  pancakeRabbits: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnyFactory: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  eth: {
    43113: '',
    43114: '0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
  },
  claimRefund: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  pointCenterIfo: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnySpecial: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  xPefi: {
    43113: '',
    43114: '0xD79A36056c271B988C5F1953e664E61416A9820F',
  },
  testXPefi: {
    43113: '',
    43114: '0xD79A36056c271B988C5F1953e664E61416A9820F',
  },
  png: {
    43113: '',
    43114: '0x60781C2586D68229fde47564546784ab3fACA982',
  },
  link: {
    43113: '',
    43114: '0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651',
  },
  zeth: {
    43113: '',
    43114: '0xf6f3eea905ac1da6f6dd37d06810c6fcb0ef5183',
  },
  zusdt: {
    43113: '',
    43114: '0x650cecafe61f3f65edd21efacca18cc905eef0b7',
  },
  lyd: {
    43113: '',
    43114: '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084',
  },
  gdl: {
    43113: '',
    43114: '0xd606199557c8ab6f4cc70bd03facc96ca576f142',
  },
  pangolinManager: {
    43113: '',
    43114: '0x912b5D41656048Ef681eFa9D32488a3fFE397994',
  },
  usdc: {
    43113: '',
    43114: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
  },
  'usdt.e': {
    43113: '',
    43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  joe: {
    43113: '',
    43114: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
  },
  usdc2: {
    43113: '',
    43114: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e'
  },
  // emperor
  emperor: {
    43113: '',
    // 43114: '0xbcb4B904DaC6708850D1167d03e6161ce4249e4d', // Emperor V2
    43114: '0xAb2B099beF83b7E3AB3d8690a5DB15f64c648697', // Emperor V1
  },
  penguinEmperor: {
    43113: '',
    43114: '0xbcb4B904DaC6708850D1167d03e6161ce4249e4d', // Emperor V2
    // 43114: '0xAb2B099beF83b7E3AB3d8690a5DB15f64c648697', // Emperor V1
  },
  penguinEmperorV3: {
    43113: '',
    // 43114: '0x7069Ce7Cb2bC25909a2B0823815a18b4bD608c09', // Emperor V3
    // 43114: '0xce71e6aa66f605e9485f78e24fe2219e70eea994'
    // 43114: '0x2562B125177BFb017cc8b04f0CDc4a8f624CBB0A'
    // 43114: '0x3739e370f05272ab94bce07576235b229b8167e8'
    43114: '0x12eb023e18656fdd65bea8d6adbd9f304b440269'
  },
  emperorPenguinDB: {
    43113: '',
    43114: '0x61B8FceCEE784981d2D7d5FbCC9AD418E729bC59',
  },
  randomDistributor: {
    43113: '',
    43114: '0x9b06252e1e6f012b9dd519a94ee02bba2ed1fc8e'
  },

  // covid emperor
  withoutBorders: {
    43113: '',
    43114: '0xCDcdF40215929d5b73Fa566791B19832ce1B4b51',
  },
  charityPenguinDB: {
    43113: '',
    43114: '0xaAD41CCf76c035DAF80d662584823215f2e0487D',
  },

  // emperor manager
  emperorManager: {
    43113: '',
    // 43114: '0xB3C4467aC0408FC07b2239D78d2596bC04eEb8f3',  // Emperor V2
    43114: '0x1edbef9bb950942297895b0f4e622df809200818', // Emperor V1
  },

  emperorManagerV2: {
    43113: '',
    43114: '0xB3C4467aC0408FC07b2239D78d2596bC04eEb8f3',  // Emperor V2
    // 43114: '0x1edbef9bb950942297895b0f4e622df809200818', // Emperor V1
  },

  emperorManagerV3: {
    43113: '',
    43114: '0xB2AED1353D0f621c07497ECc3E770a720B9D4C15',  // Emperor V3
  },

  // launchpad Sherpa
  launchPad: {
    43113: '',
    43114: '0x1c0fe0A000f6DF48B2DaBf86A19934dd6B6F9477',
  },
  boosterRocket: {
    43113: '',
    43114: '0x9035e72A23e379E505b787018b3b5aB3972D73D9',
  },
  boosterRocketPefi: {
    43113: '',
    43114: '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
  },
  boosterRocketSherpa: {
    43113: '',
    43114: '0xa5e59761ebd4436fa4d20e1a27cba29fb2471fc6',
  },
  sherpa: {
    43113: '',
    43114: '0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6',
  },
  vso: {
    43113: '',
    43114: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
  },
  evrt: {
    43113: '',
    43114: '0x3aca5545e76746a3fe13ea66b24bc0ebcc51e6b4',
  },

  // launchpad - Boofi
  launchPadBoofi: {
    43113: '',
    43114: '0xb1a3f09D7A5dFB45600931E18375501973772426',
  },
  booFiBoosterRocket: {
    43113: '',
    43114: '0x90D2652B7abB628fDf907ed4ECc4711EA8ffBAf9',
  },
  booFiBoosterRocketPayToken: {
    43113: '',
    43114: '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
  },
  booFiBoosterRocketBuyToken: {
    43113: '',
    43114: '0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0',
  },
  boofi: {
    43113: '',
    43114: '0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0',
  },

  // launchpad - Kitty
  launchPadKitty: {
    43113: '',
    43114: '0xc312ba2dA6bf972cEE8fC0d2e79b3468819E2c64',
  },
  kittyBoosterRocket: {
    43113: '',
    43114: '0x5BEC14cFBd58BFE67D477Ea32A5B2AAF2B96BF63',
  },
  kittyBoosterRocketPayToken: {
    43113: '',
    43114: '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
  },
  kittyBoosterRocketBuyToken: {
    43113: '',
    43114: '0xbca7f1998dc9ffb70b086543a808960a460abca7',
  },
  kitty: {
    43113: '',
    43114: '0xbca7f1998dc9ffb70b086543a808960a460abca7',
  },

  // launchpad - Kassandra
  launchPadKassandra: {
    43113: '',
    43114: '0x1ee646289dd3fa285d23fe82871c3459ad752ca1',
    // '0xcfd439c293dbd672b595ea584fe4a8d7904a2201',
  },
  kassandraBoosterRocket: {
    43113: '',
    43114: '0x2d2f49109ca13a9471d63a040929ceaec9a51971'
    // '0x09deec99ad781a9729daeb78df3c4c3b505b185f',
  },
  kassandraBoosterRocketPayToken: {
    43113: '',
    43114: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
  },
  kassandraBoosterRocketBuyToken: {
    43113: '',
    43114: '0xf32398dae246C5f672B52A54e9B413dFFcAe1A44',
  },
  kassandra: {
    43113: '',
    43114: '0xf32398dae246C5f672B52A54e9B413dFFcAe1A44',
  },

  // v2
  iPefi: {
    43113: '',
    43114: '0xE9476e16FE488B90ada9Ab5C7c2ADa81014Ba9Ee',
  },
  nestMigrator: {
    43113: '',
    43114: '0x20901c664765c910623A9F1fed3e35d1B90DDEd8',
  },
  // club penguin
  clubPenguinMasterChef: {
    43113: '',
    43114: '0xc96D1131B40b95c75d0336574e8e4256a0A074A1',
  },

  // avax token
  avax: {
    43113: '',
    43114: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  },
  cryptoPuffiesGoldenTicket: {
    43113: '',
    43114: '0xcedf662e33340782a132e736780f61600df6933e',
  },

  puffyIgloo: {
    43113: '',
    43114: '0x891d5046c9b7952ba449eb449d4f89825d5f3d42'
    // 43114: '0x2cE75C9a6464a2471685AE9F12300Fe29e5eB838',
  },
  puffyOldIgloo: {
    43113: '',
    43114: '0x2cE75C9a6464a2471685AE9F12300Fe29e5eB838'
    // 43114: '0x2cE75C9a6464a2471685AE9F12300Fe29e5eB838',
  },
  cryptoPuffies: {
    43113: '',
    43114: '0x457c224e4a2db059071f01ee2ff43078ac021597'
  },
  jlpMigrator: {
    43113: '',
    43114: '0xff8d8ec38f152341184842178c7da77759870f95'
  },
  cryptoPuffiesBestFriends: {
    43113: '',
    43114: '0xb55b9a49e7f3a888e29c15a02c1b044826457bd0',
  },
}
